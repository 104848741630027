
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'

export default defineComponent({
  name: 'ServicesSendAndReceiveSettings',
  components: {
    TmStatus,
    TmButton,
    InfoCard,
  },
  setup() {
    const includeSmsContentVariant = ref([
      {
        value: 'bodySubject',
        title: 'Email subject and email body',
        description: 'The email subject and body will be included in SMS content.',
      },
      {
        value: 'body',
        title: 'Only email body',
        description: 'Only the email body will be included in SMS content.',
      },
      {
        value: 'subject',
        title: 'Only email subject',
        description: 'Only the email subject will be included in SMS content.',
      },
    ])

    const includeSmsContent = ref('body')
    const includeSmsContentDefault = ref('body')

    const lengthVariant = ref([1, 2, 3, 4, 5, 6])
    const length = ref(1)
    const lengthDefault = ref(1)

    const getCharacters = (c: number): number => {
      if (c === 1) return 160
      if (c === 2) return 306
      return 306 + (c - 2) * 153
    }

    const getLengthLabel = (c: number): string => `${c} x SMS (${getCharacters(c)} characters)`

    const settingConvertText = ref(false)
    const settingConvertTextDefault = ref(false)

    const settingCutExtra = ref(true)
    const settingCutExtraDefault = ref(true)

    const settingSendEmail = ref(false)
    const settingSendEmailDefault = ref(false)

    const settingEnableEmail = ref(true)
    const settingEnableEmailDefault = ref(true)

    const customCutPhrases = ref('')
    const customCutPhrasesDefault = ref('')

    const discard = () => {
      includeSmsContent.value = includeSmsContentDefault.value
      length.value = lengthDefault.value
      settingConvertText.value = settingConvertTextDefault.value
      settingCutExtra.value = settingCutExtraDefault.value
      settingSendEmail.value = settingSendEmailDefault.value
      settingEnableEmail.value = settingEnableEmailDefault.value
      customCutPhrases.value = customCutPhrasesDefault.value
    }

    return {
      includeSmsContentVariant,
      includeSmsContent,
      includeSmsContentDefault,
      lengthVariant,
      length,
      lengthDefault,
      getLengthLabel,
      settingConvertText,
      settingConvertTextDefault,
      settingCutExtra,
      settingCutExtraDefault,
      settingSendEmail,
      settingSendEmailDefault,
      settingEnableEmail,
      settingEnableEmailDefault,
      customCutPhrases,
      customCutPhrasesDefault,
      discard,
    }
  },
})
